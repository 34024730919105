import logo from '@icons/logo.svg';

type LogoProps = {
  className?: string
}

const index = ({className}: LogoProps) => {
  return (
      <img
        src={logo}
        alt="Logo CV Crafted"
        className={className}
      />
  )
}

export default index
