import { useState } from 'react';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';

const TestimonialsSection = () => {
  const testimonials = [
    {
      name: "Marie Laurent",
      role: "Développeuse Web",
      image: "/api/placeholder/80/80",
      rating: 5,
      text: "Grâce à ce service, j'ai pu créer un CV qui a vraiment retenu l'attention des recruteurs. L'interface est intuitive et les suggestions de l'IA sont pertinentes."
    },
    {
      name: "Thomas Dubois",
      role: "Marketing Manager",
      image: "/api/placeholder/80/80",
      rating: 5,
      text: "Un outil remarquable qui m'a permis de décrocher plusieurs entretiens. La personnalisation automatique selon l'offre d'emploi est vraiment un plus."
    },
    {
      name: "Sophie Martin",
      role: "Graphiste",
      image: "/api/placeholder/80/80",
      rating: 5,
      text: "Je recommande vivement ! Les templates sont modernes et professionnels. L'analyse ATS m'a beaucoup aidé à optimiser mon CV."
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1));
  };

  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* En-tête */}
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-text mb-4">
            Ce que disent nos utilisateurs
          </h2>
          <p className="text-text-secondary max-w-2xl mx-auto text-lg">
            Découvrez les expériences de ceux qui ont déjà utilisé notre plateforme
          </p>
        </div>

        {/* Carousel */}
        <div className="relative max-w-4xl mx-auto">
          {/* Boutons de navigation */}
          <button 
            onClick={handlePrevious}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-12 lg:-translate-x-16
                     w-10 h-10 rounded-full bg-white shadow-lg flex items-center justify-center
                     text-primary hover:bg-primary hover:text-white transition-colors duration-300"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>

          <button 
            onClick={handleNext}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-12 lg:translate-x-16
                     w-10 h-10 rounded-full bg-white shadow-lg flex items-center justify-center
                     text-primary hover:bg-primary hover:text-white transition-colors duration-300"
          >
            <ChevronRight className="w-6 h-6" />
          </button>

          {/* Témoignage */}
          <div className="bg-secondary bg-opacity-30 rounded-2xl p-8 md:p-12">
            <div className="flex flex-col items-center">
              {/* Photo et info */}
              <img 
                src={testimonials[currentIndex].image}
                alt={testimonials[currentIndex].name}
                className="w-20 h-20 rounded-full object-cover mb-4 ring-4 ring-white"
              />
              
              {/* Notation */}
              <div className="flex gap-1 mb-4">
                {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                  <Star key={i} className="w-5 h-5 fill-primary text-primary" />
                ))}
              </div>

              {/* Citation */}
              <blockquote className="text-text text-lg md:text-xl italic mb-6 text-center">
                "{testimonials[currentIndex].text}"
              </blockquote>

              {/* Auteur */}
              <div className="text-center">
                <div className="font-semibold text-text">
                  {testimonials[currentIndex].name}
                </div>
                <div className="text-text-secondary">
                  {testimonials[currentIndex].role}
                </div>
              </div>
            </div>
          </div>

          {/* Indicateurs */}
          <div className="flex justify-center gap-2 mt-6">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-2 h-2 rounded-full transition-all duration-300 
                          ${index === currentIndex ? 'w-8 bg-primary' : 'bg-gray-300'}`}
                aria-label={`Voir témoignage ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;