import Logo from '@/components/common/Logo';
import { Facebook, Twitter, Linkedin, Instagram, Send } from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = {
    product: {
      title: "Produit",
      links: [
        { name: "Fonctionnalités", href: "#" },
        { name: "Tarifs", href: "#" },
        { name: "Témoignages", href: "#" },
        { name: "Guide d'utilisation", href: "#" }
      ]
    },
    company: {
      title: "Entreprise",
      links: [
        { name: "À propos", href: "#" },
        { name: "Blog", href: "#" },
        { name: "Carrières", href: "#" },
        { name: "Contact", href: "#" }
      ]
    },
    legal: {
      title: "Légal",
      links: [
        { name: "Conditions d'utilisation", href: "#" },
        { name: "Politique de confidentialité", href: "#" },
        { name: "Mentions légales", href: "#" },
        { name: "Cookies", href: "#" }
      ]
    }
  };

  const socialLinks = [
    { icon: Facebook, href: "#" },
    { icon: Twitter, href: "#" },
    { icon: Linkedin, href: "#" },
    { icon: Instagram, href: "#" }
  ];

  return (
    <footer className="bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-8">
        {/* Section principale */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-8 pb-12 border-b border-gray-200">
          {/* Logo et description */}
          <div className="lg:col-span-2">
            <Logo className="w-1/2 relative -left-1"/>
            <p className="text-text-secondary mb-6">
              Créez des CV professionnels en quelques minutes grâce à notre plateforme alimentée par l'IA.
            </p>
            {/* Réseaux sociaux */}
            <div className="flex space-x-4">
              {socialLinks.map((social, index) => {
                const Icon = social.icon;
                return (
                  <a
                    key={index}
                    href={social.href}
                    className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center
                             text-text-secondary hover:bg-primary hover:text-white
                             transition-colors duration-300"
                  >
                    <Icon className="w-5 h-5" />
                  </a>
                );
              })}
            </div>
          </div>

          {/* Liens */}
          {Object.values(footerLinks).map((category, index) => (
            <div key={index} className="lg:col-span-1">
              <h3 className="font-semibold text-text mb-4">{category.title}</h3>
              <ul className="space-y-3">
                {category.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a 
                      href={link.href}
                      className="text-text-secondary hover:text-primary transition-colors duration-300"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          {/* Newsletter */}
          <div className="lg:col-span-2">
            <h3 className="font-semibold text-text mb-4">Newsletter</h3>
            <p className="text-text-secondary mb-4">
              Restez informé des dernières fonctionnalités et actualités.
            </p>
            <form className="flex gap-2">
              <input
                type="email"
                placeholder="Votre email"
                className="flex-1 px-4 py-2 rounded-lg border border-gray-200 
                         focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              />
              <button
                type="submit"
                className="px-4 py-2 bg-primary text-white rounded-lg
                         hover:bg-opacity-90 transition-colors duration-300
                         flex items-center justify-center"
              >
                <Send className="w-5 h-5" />
              </button>
            </form>
          </div>
        </div>

        {/* Pied de page */}
        <div className="pt-8 text-center text-text-secondary text-sm">
          <p>© {currentYear} CvCrafted. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;