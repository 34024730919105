import Logo from '@/components/common/Logo';
import { useState, useEffect } from 'react';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed w-full top-0 z-50 transition-all duration-300 ${
      isScrolled ? 'bg-white shadow-md' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <div className="flex-shrink-0">
            {/* <span className="text-primary text-2xl font-bold">Logo</span> */}
            <Logo className="w-3/4 mt-3"/>
          </div>

          {/* Navigation */}
          <nav className="hidden md:flex space-x-8">
            {['Fonctionnalités', 'Prix', 'Blog', 'Contact'].map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className="text-text hover:text-primary transition-colors duration-200 font-medium"
              >
                {item}
              </a>
            ))}
          </nav>

          {/* Auth Buttons */}
          <div className="flex items-center space-x-4">
            <button className="px-4 py-2 text-text hover:text-primary transition-colors duration-200">
              Connexion
            </button>
            <button className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-opacity-90 transition-opacity duration-200">
              Inscription
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;