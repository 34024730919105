import { useState, useEffect } from 'react';
import { Users, FileCheck, Star, Award } from 'lucide-react';

const StatsSection = () => {
  const stats = [
    {
      icon: Users,
      value: 50000,
      label: "Utilisateurs",
      suffix: "+"
    },
    {
      icon: FileCheck,
      value: 100000,
      label: "CV Générés",
      suffix: "+"
    },
    {
      icon: Star,
      value: 98,
      label: "Taux de Satisfaction",
      suffix: "%"
    },
    {
      icon: Award,
      value: 85,
      label: "Taux de Réussite",
      suffix: "%"
    }
  ];

  const [counts, setCounts] = useState(stats.map(() => 0));

  useEffect(() => {
    const animationDuration = 2000; // 2 secondes
    const steps = 50; // nombre d'étapes d'animation
    const stepDuration = animationDuration / steps;

    stats.forEach((stat, index) => {
      const stepValue = stat.value / steps;
      let currentStep = 0;

      const interval = setInterval(() => {
        if (currentStep === steps) {
          clearInterval(interval);
          return;
        }

        setCounts(prevCounts => {
          const newCounts = [...prevCounts];
          newCounts[index] = Math.round(stepValue * currentStep);
          return newCounts;
        });

        currentStep++;
      }, stepDuration);
    });
  }, []);

  return (
    <section className="py-16 bg-primary bg-opacity-5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => {
            const Icon = stat.icon;
            return (
              <div 
                key={index} 
                className="flex flex-col items-center p-6 rounded-lg 
                           bg-white shadow-md hover:shadow-lg transition-shadow
                           duration-300"
              >
                <div className="w-12 h-12 mb-4 rounded-full bg-primary bg-opacity-10 
                              flex items-center justify-center">
                  <Icon className="w-6 h-6 text-primary" />
                </div>
                
                <div className="text-3xl font-bold text-primary mb-2">
                  {counts[index].toLocaleString()}{stat.suffix}
                </div>
                
                <div className="text-text-secondary text-lg text-center">
                  {stat.label}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default StatsSection;