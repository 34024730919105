
import Footer from '@components/layout/Footer'
import Header from '@components/layout/Header'
import BenefitsSection from './components/BenefitsSection'
import CtaSection from './components/CtaSection'
import FeaturesSection from './components/FeaturesSection'
import HeroSection from './components/HeroSection'
import HowItWorksSection from './components/HowItWorksSection'
import StatsSection from './components/StatsSection'
import TestimonialsSection from './components/TestimonialsSection'

const HomePage = () => {
  return (
    <>
      <Header/>
      <HeroSection/>
      <FeaturesSection/>
      <HowItWorksSection/>
      <StatsSection/>
      <TestimonialsSection/>
      <BenefitsSection/>
      <CtaSection/>
      <Footer/>
    </>
  )
}

export default HomePage
