import { FileText, Sparkles, Send, ThumbsUp } from 'lucide-react';

const HowItWorksSection = () => {
  const steps = [
    {
      icon: FileText,
      title: "Créez votre profil",
      description: "Remplissez vos informations professionnelles en quelques clics",
      color: "bg-primary"
    },
    {
      icon: Sparkles,
      title: "IA en action",
      description: "Notre IA analyse et optimise votre CV pour le rendre plus percutant",
      color: "bg-primary"
    },
    {
      icon: Send,
      title: "Personnalisez",
      description: "Adaptez votre CV selon vos préférences et l'offre d'emploi",
      color: "bg-primary"
    },
    {
      icon: ThumbsUp,
      title: "Postulez",
      description: "Téléchargez votre CV optimisé et augmentez vos chances de succès",
      color: "bg-primary"
    }
  ];

  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* En-tête de section */}
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-text mb-4">
            Comment ça marche
          </h2>
          <p className="text-text-secondary max-w-2xl mx-auto text-lg">
            Créez votre CV professionnel en 4 étapes simples
          </p>
        </div>

        {/* Timeline */}
        <div className="relative">
          {/* Ligne de connexion */}
          {/* <div className="hidden md:block absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200 -translate-y-1/2" /> */}

          {/* Étapes */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {steps.map((step, index) => (
              <div key={index} className="relative flex flex-col items-center">
                {/* Numéro et icône */}
                <div className="relative z-10">
                  <div className="w-16 h-16 rounded-full bg-secondary flex items-center justify-center mb-4">
                    <step.icon className="w-8 h-8 text-primary" />
                  </div>
                  <div className="absolute -top-2 -right-2 w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center text-sm font-bold">
                    {index + 1}
                  </div>
                </div>

                {/* Contenu */}
                <div className="text-center">
                  <h3 className="text-xl font-semibold mb-2 text-text">{step.title}</h3>
                  <p className="text-text-secondary">{step.description}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Call to Action */}
          <div className="mt-16 text-center">
            <button className="px-8 py-4 bg-primary text-white rounded-lg text-lg font-medium 
                            hover:bg-opacity-90 transition-all duration-300 
                            transform hover:-translate-y-1 shadow-lg hover:shadow-xl">
              Commencer maintenant
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;