import { FileText, Sparkles, Target, Clock } from 'lucide-react';

const FeaturesSection = () => {
  const features = [
    {
      icon: FileText,
      title: "Création de CV Intelligente",
      description: "Générez un CV professionnel en quelques minutes grâce à notre technologie basée sur l'IA."
    },
    {
      icon: Sparkles,
      title: "Personnalisation Avancée",
      description: "Adaptez facilement votre CV pour chaque offre d'emploi avec des suggestions personnalisées."
    },
    {
      icon: Target,
      title: "Analyse ATS Intégrée",
      description: "Optimisez votre CV pour les systèmes de suivi des candidatures avec notre analyse en temps réel."
    },
    {
      icon: Clock,
      title: "Mise à Jour Rapide",
      description: "Modifiez et mettez à jour votre CV instantanément selon vos besoins."
    }
  ];

  return (
    <section className="py-20 bg-secondary relative">
      {/* Motif géométrique en arrière-plan */}
      <div className="absolute inset-0 opacity-10" 
           style={{
             backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%232A9D8F' fill-opacity='0.2'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
           }}
      ></div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* En-tête de section */}
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-text mb-4">
            Fonctionnalités Principales
          </h2>
          <p className="text-text-secondary max-w-2xl mx-auto text-lg">
            Découvrez les outils puissants qui vous aideront à créer un CV qui se démarque
          </p>
        </div>

        {/* Grille de fonctionnalités */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div
                key={index}
                className="bg-white p-6 rounded-xl shadow-lg transition-all duration-300 
                         hover:shadow-xl hover:-translate-y-1 group"
              >
                <div className="mb-4 w-12 h-12 rounded-lg bg-primary bg-opacity-10 
                            flex items-center justify-center group-hover:bg-primary 
                            transition-colors duration-300">
                  <Icon 
                    className="w-6 h-6 text-primary group-hover:text-white 
                              transition-colors duration-300"
                  />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-text">
                  {feature.title}
                </h3>
                <p className="text-text-secondary">
                  {feature.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;