import { useEffect, useState } from 'react';
import { ReactComponent as SVG1 } from '@images/hero_ill_1.svg';
import { ReactComponent as SVG2 } from '@images/hero_ill_2.svg';
import { ReactComponent as SVG3 } from '@images/hero_ill_3.svg';
import { ReactComponent as SVG4 } from '@images/hero_ill_4.svg';
import { ReactComponent as SVG5 } from '@images/hero_ill_5.svg';
import { ReactComponent as SVG6 } from '@images/hero_ill_6.svg';
import { ReactComponent as SVG7 } from '@images/hero_ill_7.svg';

const HeroIllustration = () => {
  const [selectedIllustration, setSelectedIllustration] = useState(0);

  const illustrations = [
    <SVG1 key="1" className="w-full h-auto" />,
    <SVG2 key="2" className="w-full h-auto" />,
    <SVG3 key="3" className="w-full h-auto" />,
    <SVG4 key="4" className="w-full h-auto" />,
    <SVG5 key="5" className="w-full h-auto" />,
    <SVG6 key="6" className="w-full h-auto" />,
    <SVG7 key="7" className="w-full h-auto" />
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * illustrations.length);
    setSelectedIllustration(randomIndex);
  }, []);

  return (
    <div className="w-full transition-opacity duration-500 ease-in-out">
      {illustrations[selectedIllustration]}
    </div>
  );
};

export default HeroIllustration;