import HeroIllustration from './HeroIllustration';

const HeroSection = () => {
    return (
      <section className="relative min-h-screen bg-gradient-to-br from-secondary to-background flex items-center">
        {/* Container principal */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="pt-32 pb-20 md:pt-40 md:pb-28">
            {/* Grid pour le contenu */}
            <div className="grid md:grid-cols-2 gap-8 items-center">
              {/* Colonne de gauche: Texte */}
              <div className="space-y-6">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-text">
                  Créez votre CV professionnel <span className="text-primary">en quelques minutes</span>
                </h1>
                
                <p className="text-lg md:text-xl text-text-secondary max-w-lg">
                  Générez un CV percutant avec l'aide de l'IA et augmentez vos chances de décrocher l'emploi de vos rêves
                </p>
                
                {/* CTA Button */}
                <button className="mt-8 px-8 py-4 bg-primary text-white rounded-lg text-lg font-medium 
                  hover:bg-opacity-90 transform transition-all duration-300 hover:-translate-y-1 
                  shadow-lg hover:shadow-xl">
                  Commencer gratuitement
                </button>
                
                {/* Trust indicators */}
                <div className="flex flex-wrap items-center gap-4 mt-6 text-text-secondary">
                  <div className="flex items-center transition-transform duration-300 hover:scale-105">
                    <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
                    </svg>
                    <span>Gratuit</span>
                  </div>
                  <div className="flex items-center transition-transform duration-300 hover:scale-105">
                    <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 101.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z" clipRule="evenodd"/>
                    </svg>
                    <span>Simple</span>
                  </div>
                  <div className="flex items-center transition-transform duration-300 hover:scale-105">
                    <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
                    </svg>
                    <span>Professionnel</span>
                  </div>
                </div>
              </div>
  
              {/* Colonne de droite: Illustration */}
              <div className="relative transition-transform duration-500 hover:scale-105">
                <HeroIllustration/>
              </div>
            </div>
          </div>
        </div>
  
        {/* Forme ondulée en bas */}
        {/* <div className="absolute bottom-0 left-0 w-full overflow-hidden leading-none">
          <svg 
            className="relative block w-full h-24"
            data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 1200 120" 
            preserveAspectRatio="none"
          >
            <path 
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" 
              className="fill-white"
            />
          </svg>
        </div> */}
      </section>
    );
  };
  
  export default HeroSection;