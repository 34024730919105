import { ArrowRight, CheckCircle } from 'lucide-react';

const CtaSection = () => {
  const trustFactors = [
    "Essai gratuit de 14 jours",
    "Satisfaction garantie",
    "Support client 7j/7",
    "Annulation à tout moment"
  ];

  return (
    <section className="py-20 relative overflow-hidden">
      {/* Fond avec dégradé et motif */}
      <div className="absolute inset-0 bg-gradient-to-br from-primary/90 to-primary">
        <div className="absolute inset-0 opacity-10" 
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.2'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
          }}
        />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="flex flex-col lg:flex-row items-center gap-12 lg:gap-16">
          {/* Contenu */}
          <div className="w-full lg:w-1/2 text-white">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
              Prêt à booster votre carrière?
            </h2>
            
            <p className="text-xl mb-8 text-white/90">
              Rejoignez des milliers de professionnels qui ont déjà transformé leur recherche d'emploi avec nos outils innovants.
            </p>

            {/* Points de confiance */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
              {trustFactors.map((factor, index) => (
                <div key={index} className="flex items-center gap-2">
                  <CheckCircle className="w-5 h-5 text-white/90" />
                  <span className="text-white/90">{factor}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Formulaire */}
          <div className="w-full lg:w-1/2">
            <div className="bg-white rounded-2xl shadow-xl p-8">
              <h3 className="text-2xl font-bold text-text mb-6">
                Commencez gratuitement
              </h3>

              <form className="space-y-4">
                <div>
                  <input
                    type="email"
                    placeholder="Votre email professionnel"
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 
                             focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>

                <div>
                  <input
                    type="password"
                    placeholder="Choisissez un mot de passe"
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 
                             focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>

                <button
                  type="submit"
                  className="w-full bg-primary text-white rounded-lg px-4 py-3 
                           flex items-center justify-center gap-2 font-medium
                           hover:bg-opacity-90 transition-all duration-300"
                >
                  <span>Créer mon compte</span>
                  <ArrowRight className="w-5 h-5" />
                </button>

                <p className="text-text-secondary text-sm text-center mt-4">
                  En vous inscrivant, vous acceptez nos{' '}
                  <a href="#" className="text-primary hover:underline">Conditions d'utilisation</a>
                  {' '}et notre{' '}
                  <a href="#" className="text-primary hover:underline">Politique de confidentialité</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;