import { Zap, Shield, Target } from 'lucide-react';
import SVGGen from '@images/fast_gen.svg';
import SVGOptimisation from '@images/optimisation.svg';
import SVGPersonalisation from '@images/personalisation.svg';

const BenefitsSection = () => {
  const benefits = [
    {
      title: "Génération Instantanée de CV",
      description: "Créez votre CV professionnel en quelques minutes grâce à notre technologie d'IA avancée qui s'adapte à votre profil et au poste visé.",
      icon: Zap,
      image: SVGGen,
      features: [
        "Templates modernes et professionnels",
        "Suggestions de contenu personnalisées",
        "Mise en page automatique"
      ]
    },
    {
      title: "Optimisation ATS Garantie",
      description: "Maximisez vos chances d'être sélectionné avec notre système d'optimisation intégré qui analyse et améliore la compatibilité de votre CV.",
      icon: Shield,
      image: SVGOptimisation,
      features: [
        "Analyse des mots-clés",
        "Score de compatibilité",
        "Suggestions d'amélioration"
      ]
    },
    {
      title: "Personnalisation Intelligente",
      description: "Adaptez facilement votre CV pour chaque offre d'emploi grâce à nos outils de personnalisation avancés et nos recommandations ciblées.",
      icon: Target,
      image: SVGPersonalisation,
      features: [
        "Adaptation automatique au poste",
        "Mise en avant des compétences clés",
        "Sections personnalisables"
      ]
    }
  ];

  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* En-tête */}
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-text mb-4">
            Pourquoi nous choisir
          </h2>
          <p className="text-text-secondary max-w-2xl mx-auto text-lg">
            Découvrez les avantages qui font la différence
          </p>
        </div>

        {/* Liste des avantages */}
        <div className="space-y-24">
          {benefits.map((benefit, index) => {
            const Icon = benefit.icon;
            const isEven = index % 2 === 0;

            return (
              <div 
                key={index}
                className={`flex flex-col items-center gap-8 lg:gap-16 
                           ${isEven ? 'lg:flex-row' : 'lg:flex-row-reverse'}`}
              >
                {/* Image */}
                <div className="w-full lg:w-1/2">
                  <div className="relative rounded-2xl overflow-hidden">
                    <img
                      src={benefit.image}
                      alt={benefit.title}
                      className="w-full h-auto transform hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                </div>

                {/* Contenu */}
                <div className="w-full lg:w-1/2">
                  {/* Icône et titre */}
                  <div className="flex items-center gap-4 mb-6">
                    <div className="w-12 h-12 rounded-lg bg-primary bg-opacity-10 
                                  flex items-center justify-center">
                      <Icon className="w-6 h-6 text-primary" />
                    </div>
                    <h3 className="text-2xl font-bold text-text">
                      {benefit.title}
                    </h3>
                  </div>

                  {/* Description */}
                  <p className="text-text-secondary text-lg mb-8">
                    {benefit.description}
                  </p>

                  {/* Points clés */}
                  <ul className="space-y-4">
                    {benefit.features.map((feature, featureIndex) => (
                      <li 
                        key={featureIndex}
                        className="flex items-center gap-3"
                      >
                        <div className="w-6 h-6 rounded-full bg-primary bg-opacity-10 
                                      flex items-center justify-center">
                          <div className="w-2 h-2 rounded-full bg-primary"/>
                        </div>
                        <span className="text-text">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;